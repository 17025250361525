import { CursorContextProvider, Cursor } from "../components/general/Cursor";
import Nav from "../components/general/Nav";
import Hero from "../components/supportus/Hero";
import Intro from "../components/supportus/Intro";
import SupportUs from "../components/supportus/SupportUs";
import styled from "styled-components";
import { colors } from "../assets/colors";

const Container = styled.div`
  @media only screen and (max-width: 1500px) {
    margin-bottom: 10px;
    display: none;
  } 
`;

const UnderConstruction = styled.div`
  display: none;

  /* Display on mobile and tablet screens (up to 1024px) */
  @media only screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 50vh;
    background-color: ${colors.black};
    color: white;
    border-radius: 30px 30px 0px 0px;
    font-size: 24px;
    z-index: 10;
    text-align: center;
    font-size: 18px;
  }
`;

function Support() {
  return (
    <CursorContextProvider>
      <Cursor />
      <Hero />
      <Container>
        <Intro />
        <SupportUs />
      </Container>
      <UnderConstruction>
        <p>Sorry!</p>
        <p>The mobile version of this page is not live.</p>
        <p>(our dev team is currently cramming for a OS exam)</p>
        <p> Please visit this page on your desktop :)</p>
      </UnderConstruction>
      <Nav color={0} home={1} />
    </CursorContextProvider>
  );
}

export default Support;
