import styled from "styled-components";
import { colors } from "../../assets/colors";
import { useSectionCursor } from "../general/Cursor";
import { useCursorHandlers } from "../general/Cursor";
import work from "../../assets/work.png"
import reallynight from "../../assets/reallynight.png"
import cohort from "../../assets/cohort.png"
import { Link } from "react-router-dom";

const SectionContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  color: ${colors.black};
  background-color: ${colors.white};
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-family: ReadexPro, sans-serif;
  margin-bottom: 2.5rem;
  color: ${colors.black};
  text-align: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 4rem;
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
`;

const ImageRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0rem;
`;

const ImageColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0rem;
`;


const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  @media (max-width: 450px) {
    max-width: 100%;
    height: 40vh;
  }
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  font-family: ReadexPro, sans-serif;
  margin-bottom: 1rem;
`;

const Text = styled.p`
  font-size: 1rem;
  font-family: ReadexPro, sans-serif;
  line-height: 1.5;
  @media (max-width: 450px) {
    font-size: 0.3rem;
  }
`;

const DonateButton = styled.button`
  width: 100%;
  max-width: 300px; 
  background-color: ${colors.red};
  color: ${colors.white};
  border: none;
  padding: 1.5rem 3rem; 
  font-size: 1.85rem;  
  font-family: ReadexPro, sans-serif;
  margin: 1rem 0;
  text-align: center;
  text-decoration: none;
  border-radius: 30px; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  
  &:hover {
    background-color: darken(${colors.red}, 10%);  /* Optional hover effect */
  }

  @media (max-width: 768px) {
    font-size: 1.25rem;  /* Adjust font size for smaller screens */
    padding: 1rem 2rem;
  }
`;

const SocialsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0;
`;

const SocialButton = styled(DonateButton)`
  background-color: ${colors.white};
  color: ${colors.black};
  border: 1px solid ${colors.black};
`;

const FooterImage = styled.div`
  width: 100%;
  height: 150px;
  background-color: ${colors.gray};
  margin-top: 2rem;
`;

const SupportUs = () => {
  const cursorHandlers = useSectionCursor({color: colors.black});
  const normalCursorHandlers = useCursorHandlers();
  return (
    <SectionContainer {...cursorHandlers}>
      <ContentContainer>
        <Title>Help support our founders and interns.</Title>
        
        <Row>
          <Column>
            <Image src={work} alt="Work Image" />
          </Column>
          <Column>
            <Subtitle>Young founders often hit a plateau in product development.</Subtitle>
            <Text>
              They find early success, but soon the scope of the idea expands beyond their capabilities. They are forced to either scale back their ambition or source the capital required to create the team they need.
            </Text>
          </Column>
        </Row>

        <Row>
          <Column>
            <Subtitle><span style={{ color: colors.red }}>Momentum offers a third option.</span></Subtitle>
            <Text>
              We provide young entrepreneurs with the team and support needed to scale their startup. Founders in our program get access to over 100 potential interns who are eager to build and innovate.
            </Text>
            <Text>Zero equity. All pro bono.</Text>
          </Column>
          <Column>
            <Image src={reallynight} alt="Really Night Image" />
          </Column>
        </Row>

                {/* <Text>Donations will help cover our Demo Day catering,  HackTime snack bar, promotional materials, and admin software. Help us provide resources and support to our founders and interns at no cost.</Text>
        <Text>Thank you for your support!</Text> */}
      </ContentContainer>
            {/* <SocialsContainer> */}
              {/* <SocialButton>Follow and share our socials</SocialButton>
              <SocialButton>Be a guest speaker</SocialButton> */}
            {/* </SocialsContainer> */}
            {/* <Button as={Link} to="/stripe">DONATE HERE</Button> */}
            {/* <Text>Email team@txmomentum.org to speak at our events and connect with our members!</Text> */}
      <ImageRow>
        <ImageColumn>
          <div style={{ position: 'relative', width: '100%', margin: 0, padding: 0 }}>
          <div style={{ width: '100%', position: 'absolute', display: 'flex', justifyContent: 'center', top: '-50px' }}>
            <DonateButton {...normalCursorHandlers} as={Link} to="/stripe">DONATE HERE</DonateButton>
          </div>
            <Image src={cohort} alt="Cohort Image" />
            
            {/* Top Text */}
            <div style={{
              position: 'absolute',
              top: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              left: '50%',
              transform: 'translateX(-50%)',
              color: 'white',
              padding: '5px 10px',
              borderRadius: '5px',
              textAlign: 'center', 
              top: '10%',
            }}>
              <Text style={{ color: colors.black, fontSize: '1.5rem', }}>
                Donations will help cover our Demo Day catering, HackTime snack bar, promotional materials, and admin software. 
                Help us provide resources and support to our founders and interns <span style={{fontWeight: 'bold'}}> at no cost.</span>
              </Text>
            </div>
            
            {/* Bottom Text */}
            <div style={{
              position: 'absolute',
              bottom: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              left: '50%',
              transform: 'translateX(-50%)',
              color: 'white',
              padding: '5px 10px',
              borderRadius: '5px',
              textAlign: 'center'
            }}>
              <Text style={{ color: colors.white, fontSize: '2rem'}}>Thank you for your support!</Text>
            </div>
          </div>
        </ImageColumn>
      </ImageRow>

    </SectionContainer>
  );
};

export default SupportUs;
