import Payment from "../components/stripe/Payment";
import { useState } from "react";
import StripeStyle from "../components/stripe/StripeStyle.css";

const Stripe = () => {
  const [amount, setAmount] = useState("1");

  const handleAmountChange = (e) => {
    const input = e.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;

    if (regex.test(input)) {
      setAmount(input);
    }
  };

  return (
    <div style={{cursor: "default"}}>
      <label style={{cursor: "default"}}>
        Payment amount:
        <div style={{cursor: "default", display: "flex", flexDirection: "row"}}>
        <span style={{cursor: "default"}}>$</span>
        <input
          type="text"
          value={amount}
          onChange={handleAmountChange}
          placeholder="Enter amount"
          style={{cursor: "default"}}
        />
        </div>
      </label>
      <Payment key={Math.round(parseFloat(amount) * 100)} amount={Math.round(parseFloat(amount) * 100)} />
    </div>
  );
};

export default Stripe;
